import {
    match_search,
    searchCompetitionDocuments,
    search_score,
    user_score_rank,
    competition_entry,
    upload_num,
    is_enter_match,
    search_score_everyday,
    user_submit_log,
    integral_rank,
    cal_code_score
} from "../../api/SingleItem.js";
import {
    search_question,
    delete_question,
    ask_question,
    answer_question,
} from "../../api/qa.js";
import axios from "axios";
import {
    publicbaseURLs
} from "@/utils/request";
import {
    getToken,
    getlevel_info
} from "@/utils/auth";
import editor from "../ManagementCenter/editor.vue";
import {
    Notification
} from "element-ui";
import * as echarts from "echarts";
export default {
    name: "SingleItem",
    components: {
        editor,
    },
    data() {
        return {
            loading: false,
            EnrollState: -1, //报名入口按钮
            matchstate: 0, //比赛状态
            ThisLogin: getToken(),
            UrlApi: null, //提交文件路劲
            SingleitemData: {}, //单个比赛数据
            SingleitemDataFile: [], //单个比赛数据文件
            UserUploadNumber: null, //当前比赛用户已提交次数
            today_submittimes: "", //用户当天提交次数
            realname: null, //当前比赛用户名称
            PersonalScoreAll: [], //当前比赛用户所有提交历史
            rankingListAll: [], //当前比赛排名
            EntranceChangeState: 0,
            ScoreParams: {
                //分数分页字段
                pageNum: 1,
                pageSize: 10,
            },
            ScoreTotal: 0, //分数分页总数

            LogsParams: {
                keywords: '',
                //分数分页字段
                pageNum: 1,
                pageSize: 10,
            },
            userSubmitLogData: [],
            SubmitLogTotal: 0,

            rankingeParams: {
                //排名分页字段
                keywords: "",
                pageNum: 1,
                pageSize: 50,
                challenge_id: null,
                filtrate: "score",
            },
            rankingTotal: 0, //排名分页总数

            fileList: [], //文件
            QATitle: "", //提问搜索
            Params: {
                //提问分页
                pageNum: 1,
                pageSize: 8,
            },
            Total: 0, //分页总数
            dataList: [], //提问数据
            level_info: -1,
            show: -1,
            question_id: null,
            replyState: false,
            State: false,
            question_content: null,
            answer_content: null,
            datascore: false,
            activeName: "first",
            ParamsRank: {
                keywords: "",
                challenge_id: "",
                pageNum: 1,
                pageSize: 10,
            },
            RankList: [],
            RankTotal: 0,
            IsPlayGameState: undefined, //是否比赛开始
        };
    },
    created() {
        this.UrlApi = publicbaseURLs() + "/score_calculation/" + this.$route.query.id;
    },
    mounted() {

        // 获取比赛状态
        // this.matchstate = this.$route.query.matchstate;
        // 默认展示比赛简介
        this.OclickEntrance(0);
        // 判断是否为个人信息页面跳转传参
        if (this.$route.query.state) {
            this.OclickEntrance(this.$route.query.state);
        }
        // 判断报名入口显示状态
        if (getToken()) {
            this.GetEnrollState();
        } else {
            this.EnrollState = -1;
        }
    },
    methods: {
        ansiToHtml(text) {
            // 示例：将ANSI颜色代码转换为带有CSS类的span元素
            return text.replace(/\x1b\[1;32m(.*?)\x1b\[0m/g, '<span style="color: green;font-weight: 600;">\$1</span>').replace(/\x1b\[1;31m(.*?)\x1b\[0m/g, '<span style="color: red;font-weight: 600;">\$1</span>').replace(/\n/g, '<br>')
            // 添加更多的替换规则来处理其他颜色和样式
        },
        // 是否进行比赛
        /**
         * [isDuringDate 比较当前时间是否在指定时间段内]
         * @author bingtangmeng
         * @DateTime 2023-1-06
         * @version 1.0
         * @param  date  [beginDateStr] [开始时间]
         * @param  date  [endDateStr]  [结束时间]
         * @return  Boolean
         */
        IsPlayGame(beginDateStr, endDateStr) {
            const curData = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr)
            if (curData >= beginDate && curData <= endDate) {
                return true;
            }
            return false;
        },
        // 提交比赛数据
        SubmitScore() {
            this.$confirm('比赛结束后将会对所有选手代码进行复核，直接读取预测结果Excel文件并上传的情况将被视为分数无效。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.commit("app/CHANGE_LOADING", true);
                console.log(this.$route.query.id)
                cal_code_score(this.$route.query.id).then(res => {
                    console.log(res)
                    this.$store.commit("app/CHANGE_LOADING", false);
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                    } else if (res.code == 300) {
                        this.$message({
                            message: '错了哦，这是一条错误消息',
                            type: 'warning'
                        });
                    } else if (res.code == 400) {
                        this.$message({
                            message: '执行代码失败',
                            type: 'warning'
                        });
                    } else if (res.code == 10039) {
                        this.$message({
                            message: '超过最大提交次数',
                            type: 'warning'
                        });
                    } else if (res.code == 10042) {
                        this.$message({
                            message: '未报名该比赛',
                            type: 'warning'
                        });
                    } else if (res.code == 10051) {
                        this.$message({
                            message: '长度不匹配',
                            type: 'warning'
                        });
                    } else if (res.code == 10038) {
                        this.$message({
                            message: '提交结果格式不正确',
                            type: 'warning'
                        });
                    } else if (res.code == 10053) {
                        this.$message({
                            message: '数据类型错误,支持int, float',
                            type: 'warning'
                        });
                    } else if (res.code == 10052) {
                        this.$message({
                            message: '存在空值',
                            type: 'warning'
                        });
                    } else if (res.code == 10075) {
                        this.$message({
                            message: '连续提交警告！',
                            type: 'warning'
                        });
                    }
                }).catch(err => {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.$message.error(err.msg);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                });
            });

        },
        RankingStyle(rank) {
            if (rank == 1 || rank == 2 || rank == 3) {
                return {
                    backgroundColor: "rgba(245, 100, 80, 0.3)",
                    fontWeight: 600,
                };
            } else if (rank == 4 || rank == 5 || rank == 6) {
                return {
                    backgroundColor: "rgba(236,167,73, 0.3)",
                    fontWeight: 600,
                };
            } else if (rank == 7 || rank == 8 || rank == 9 || rank == 10) {
                return {
                    backgroundColor: "rgba(73,205,133, 0.3)",
                    fontWeight: 600,
                };
            } else {
                return "";
            }
        },
        // 计算比赛状态
        CalculateGameStatus(
            registration_time,
            start_time,
            en_time,
            challenge_status
        ) {
            if (challenge_status == "-1") {
                return "已暂停";
            } else if (challenge_status == "1") {
                return "已结束";
            } else if (challenge_status == "0") {
                if (
                    new Date(this.getNowDate()) > new Date(registration_time) &&
                    new Date(this.getNowDate()) < new Date(start_time)
                ) {
                    return "报名中";
                } else if (
                    new Date(this.getNowDate()) > new Date(start_time) &&
                    new Date(this.getNowDate()) < new Date(en_time)
                ) {
                    return "进行中";
                } else {
                    return "进行中";
                }
            }
        },
        // 格式化日对象
        getNowDate() {
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear(); // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds(); //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return (
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hour +
                sign2 +
                minutes +
                sign2 +
                seconds
            );
        },
        // 是否报名
        GetEnrollState() {
            is_enter_match({
                challenge_id: this.$route.query.id,
            })
                .then((res) => {
                    if (res.code == 200) {
                        this.EnrollState = res.registration_status;
                    }
                })
                .catch((err) => {
                    this.EnrollState = err.registration_status;
                });
        },
        // 废除积分功能
        // GetintegralRank(id,type){
        // 	if (type == "搜索"){
        // 		this.ParamsRank.pageNum = 1;
        // 		this.ParamsRank.pageSize = 10;
        // 	}
        // 	this.ParamsRank.challenge_id = id;
        // 	integral_rank(this.ParamsRank).then((res) => {
        //         if (res.code == 200) {
        //             this.RankList = res.data.list;
        //             this.RankTotal = res.data.total;
        //         }
        //     });
        // },
        // 获取提问问题回复数据
        getdataQA(Id) {
            search_question(Id, this.Params).then((res) => {
                this.dataList = res.data.list;
                this.Total = res.data.total;
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        // 发送提问
        sendOutQA() {
            ask_question({
                question_content: this.question_content,
                challenge_id: this.$route.query.id,
            }).then((res) => {
                if (res.code == 200) {
                    this.State = false;
                    this.getdataQA(this.$route.query.id);
                    this.$message({
                        type: "success",
                        message: "发起提问成功!",
                    });
                }
            });
        },
        // 发布回答
        sendOutreply() {
            answer_question({
                question_id: this.question_id,
                answer_content: this.answer_content,
            }).then((res) => {
                if (res.code == 200) {
                    this.replyState = false;
                    this.getdataQA(this.$route.query.id);
                    this.$message({
                        type: "success",
                        message: "发布回答成功!",
                    });
                }
            });
        },
        // 发起回复
        reply(item) {
            if (getToken()) {
                this.question_id = item.question_id;
                this.replyState = true;
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 发起提问
        launchQA() {
            if (getToken()) {
                this.State = true;
                this.question_content = null;
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 点击查看回复
        ChangeState(item, index) {
            if (item.length > 0) {
                if (this.show == index) {
                    this.show = -1;
                    return;
                }
                this.show = index;
            } else {
                this.$message({
                    message: "暂无回复信息",
                    type: "warning",
                });
            }
        },
        // 删除评论
        DeleteComment(item) {
            this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({
                        question_id: item.question_id,
                    }).then((res) => {
                        if (res.code == 200) {
                            this.getdataQA(this.$route.query.id);
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 删除回复
        DeleteReply(item, index) {
            var CopyParams = JSON.parse(JSON.stringify(this.Params));
            this.$confirm("此操作将永久删除该回复, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({
                        answer_id: item.answer_id,
                    }).then((res) => {
                        if (res.code == 200) {
                            this.Params = CopyParams;
                            this.getdataQA(this.$route.query.id);
                            this.show = index;
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        SingleitemDow(Url) {
            this.IsPlayGame(
                this.SingleitemData.start_time,
                this.SingleitemData.en_time
            ); //判断当前是否进行比赛
            if (this.IsPlayGame(this.SingleitemData.start_time, this.SingleitemData.en_time)) {
                window.location.href = Url;
            } else {
                this.$message({
                    message: "未在比赛时间内，无法下载",
                    type: "warning",
                });
            }
        },
        RegistrationParameters() {
            if (getToken()) {
                competition_entry({
                    challenge_id: this.SingleitemData.challenge_id,
                })
                    .then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: "报名成功",
                            });
                            // this.EnrollState = 1
                            this.GetEnrollState();
                        }
                    })
                    .catch(() => {
                        // this.$message({
                        //   type: "error",
                        //   message: "报名失败",
                        // });
                    });
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        OclickEntrance(item) {
            if (item == 0) {
                this.getdata(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 1) {
                this.getdataFile(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 2) {
                if (getToken()) {
                    this.EntranceChangeState = item;
                    this.getdatascore(this.$route.query.id);
                    this.GetUserSubmitLog(this.$route.query.id)
                    this.$nextTick(() => {
                        search_score_everyday({
                            challenge_id: this.$route.query.id,
                        }).then((res) => {
                            this.getdatascoreLineChar(
                                res.date_list,
                                res.socre_list
                            );
                        });
                    });
                } else {
                    this.$message({
                        message: "请先登录用户",
                        type: "warning",
                    });
                    this.$router.push("/login");
                }
            } else if (item == 3) {
                if (getToken()) {
                    this.GetDataUserUploadNumner(this.$route.query.id);
                    this.EntranceChangeState = item;
                } else {
                    this.$message({
                        message: "请先登录用户",
                        type: "warning",
                    });
                    this.$router.push("/login");
                }
            } else if (item == 4) {
                this.getdataranking(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 5) {
                this.level_info = getlevel_info() || this.$store.state.user.Store_level_info;
                this.getdataQA(this.$route.query.id);
                this.EntranceChangeState = item;
            }
            // else if (item == 6) {
            // 	this.GetintegralRank(this.$route.query.id);
            //     this.EntranceChangeState = item;
            // }
            else {
                return;
            }
        },
        // 获取比赛简介
        getdata(id) {
            match_search({
                challenge_id: id,
            }).then((res) => {
                this.SingleitemData = res;
                this.IsPlayGameState = this.IsPlayGame(this.SingleitemData.start_time, this.SingleitemData.en_time); //判断当前是否进行比赛
            });
        },
        // 获取比赛文件
        getdataFile(id) {
            searchCompetitionDocuments({
                challenge_id: id,
            }).then((res) => {
                this.SingleitemDataFile = res.data;
            });
        },
        // 获取个人分数
        getdatascore(id) {
            var ID = id || this.$route.query.id;
            this.ScoreParams.challenge_id = ID;
            search_score(this.ScoreParams)
                .then((res) => {
                    this.PersonalScoreAll = res.data.list;
                    this.ScoreTotal = res.data.total || 0;
                })
                .catch((err) => {
                    Notification.error({
                        title: err.msg + "，暂无数据",
                    });
                });
        },
        GetUserSubmitLog(id) {
            var ID = id || this.$route.query.id;
            this.LogsParams.challenge_id = ID;
            user_submit_log(this.LogsParams)
                .then((res) => {
                    console.log(res.data.list, '__________')
                    this.userSubmitLogData = res.data.list;
                    this.SubmitLogTotal = res.data.total || 0;
                })
                .catch((err) => {
                    Notification.error({
                        title: err.msg + "，暂无数据",
                    });
                });
        },
        getdatascoreLineChar(date_list, socre_list) {
            var myChart = echarts.init(document.getElementById("sLineChars"));
            var fontColor = "#30eee9";
            var option = {
                backgroundColor: "#11183c",
                grid: {
                    left: "5%",
                    right: "5%",
                    top: "5%",
                    bottom: "12%",
                    containLabel: true,
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        color: fontColor,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#397cbc",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: "#195384",
                        },
                    },
                    data: date_list,
                },],
                yAxis: [{
                    type: "value",
                    name: "",
                    min: 0,
                    max: 1,
                    axisLabel: {
                        formatter: "{value}",
                        textStyle: {
                            color: "#2ad1d2",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#27b4c2",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#11366e",
                        },
                    },
                },],
                series: [{
                    name: "每日分数",
                    type: "line",
                    stack: "AUC",
                    symbol: "circle",
                    symbolSize: 8,
                    itemStyle: {
                        normal: {
                            color: "#0092f6",
                            lineStyle: {
                                color: "#0092f6",
                                width: 1,
                            },
                        },
                    },
                    markPoint: {
                        itemStyle: {
                            normal: {
                                color: "red",
                            },
                        },
                    },
                    data: socre_list,
                },],
                dataZoom: [{
                    show: true,
                    height: 12,
                    xAxisIndex: [0],
                    bottom: 20,
                    start: 0,
                    end: 70,
                    handleIcon: "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
                    handleSize: "100%",
                    handleStyle: {
                        color: "#d3dee5",
                    },
                    textStyle: {
                        color: "#fff",
                    },
                    borderColor: "#90979c",
                },
                    // {
                    //     type: "inside",
                    //     show: true,
                    //     height: 10,
                    //     start: 1,
                    //     end: 35,
                    // },
                ],
            };
            myChart.setOption(option);
        },
        // 获取当前比赛用户提交次数
        GetDataUserUploadNumner(id) {
            var ID = id || this.$route.query.id;
            upload_num({
                challenge_id: ID,
            })
                .then((res) => {
                    if (res.code == 200) {
                        this.UserUploadNumber = res.submittimes;
                        this.today_submittimes = res.today_submittimes;
                        this.realname = res.realname;
                        return true;
                    }
                })
                .catch(() => {
                    return false;
                });
        },
        // 下载比赛排名
        getdatarDownloadCompetitionRanking(id, pageNum, pageSize, paging) {
            const postparams = {
                keywords: "",
                challenge_id: id,
                pageNum: pageNum,
                pageSize: pageSize,
                filtrate: "score",
                paging: paging,
            };
            axios({
                // responseType: 'blob'
                url: publicbaseURLs() + "/search/download_user_rank",
                method: "post",
                data: postparams,
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                    // "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                //这里res.data是返回的blob对象,即对应的二进制流
                let blob = res.data;
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                let fileName = res.headers["content-disposition"] ?
                    res.headers["content-disposition"]
                        .split(";")[1]
                        .split("=")[1] :
                    new Date().getTime() + ".xlsx";

                downloadElement.href = href;
                downloadElement.download = decodeURIComponent(fileName); //解码
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href); //释放掉blob对象
            });
        },
        // 获取个人排名
        getdataranking(id, type) {
            if (type == "搜索") {
                this.rankingeParams.pageNum = 1;
                this.rankingeParams.pageSize = 10;
            }
            this.loading = true;
            var ID = id || this.$route.query.id;
            this.rankingeParams.challenge_id = ID;
            integral_rank(this.rankingeParams).then((res) => {
                if (res.code == 200) {
                    this.rankingListAll = res.data.list;
                    this.rankingTotal = res.data.total || 0;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
        },
        ChangeRank(id, type) {
            this.loading = true;
            var ID = id || this.$route.query.id;
            this.rankingeParams.challenge_id = ID;
            this.rankingeParams.filtrate = type;
            integral_rank(this.rankingeParams).then((res) => {
                if (res.code == 200) {
                    this.rankingListAll = res.data.list;
                    this.rankingTotal = res.data.total || 0;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
        },
        // 上传之前判断类型
        handleBeforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            // .jpg,.jpeg,.png,.pdf,.PDF
            const extension1 = testmsg === "xlsx";
            // const isLt2M = file.size / 1024 / 1024 < 10
            if (!extension1) {
                this.$message({
                    message: "上传文件只能是.xlsx格式!",
                    type: "warning",
                });
                return false;
            } else {
                return true;
            }
        },
        handleChange(file) {
            if (!file) return;
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isLt2M =
                file.size / 1024 / 1024 < Number(this.SingleitemData.file_size);
            if (
                testmsg !== "xlsx" &&
                testmsg !== "csv"
            ) {
                this.$refs.upload.clearFiles();
                this.$message({
                    message: "上传文件只能是 .xlsx,.csv格式!",
                    type: "warning",
                });
                return false;
            }
            if (!isLt2M) {
                this.$refs.upload.clearFiles();
                this.$message({
                    message: `上传文件大小不能超过 ${this.SingleitemData.file_size}MB!`,
                    type: "warning",
                });
                return false;
            }
            this.fileList = file.raw;
        },
        handleSuccess() {
            this.$store.commit("app/CHANGE_LOADING", true);
            var data = new FormData();
            data.append("file", this.fileList);
            data.append("token", getToken());
            axios({
                url: this.UrlApi,
                method: "post",
                data: data,
                headers: {
                    Authorization: getToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                        this.GetDataUserUploadNumner(this.$route.query.id);
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.fileList = [];
                        this.EntranceChangeState = 2;
                        this.getdatascore(this.$route.query.id);
                        this.GetUserSubmitLog(this.$route.query.id)
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.fileList = [];
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "上传失败",
                    });
                    this.$store.commit("app/CHANGE_LOADING", true);
                    this.fileList = [];
                });
        },
        handleError() { },
    },
};
